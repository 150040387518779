import { useAuthContext } from 'noddi-provider';
import { LoadingScreen, VerticalLayout } from 'noddi-ui';
import { Navigate } from 'react-router-dom';

import { getNavItems } from '../../NavItems';
import routes from '../appRoutes';

/**
 * A wrapper component for VerticalLayout that handles translation of navigation items.
 *
 * The reason for this component's existence is to have let the navItems be translated, which wasn't the
 * case with the previous implementation of VerticalLayout which only read the navItems on mount
 */
const HomePrivateLayout = () => {
  const navItems = getNavItems();

  const { isLoggedIn, tokenLoginError, isTokenLoadingOnMount } = useAuthContext();

  if (isTokenLoadingOnMount) {
    return <LoadingScreen />;
  }

  if (!isLoggedIn) {
    return <Navigate to={routes.login.getBasePath()} />;
  }

  if (tokenLoginError && !isLoggedIn) {
    if (tokenLoginError?.response?.status === 401) {
      return <Navigate to={routes.login.getBasePath()} />;
    }
  }
  return (
    <VerticalLayout
      translations={{ profile: 'My account', logout: 'Log out' }}
      navItems={navItems}
      alwaysShowMobileNav
    />
  );
};

export default HomePrivateLayout;
