import { cva, type VariantProps } from 'class-variance-authority';

import { ReactNode } from 'react';
import { cn } from '../helpers/utilts';
import { NoddiIconButton } from './NoddiIconButton';

const toastVariants = cva(
  'flex animate-toastInOut items-center justify-start gap-4 rounded-lg border-l-8 text-left  text-primary-black',
  {
    variants: {
      variant: {
        success: 'border-signal-success bg-signal-success50',
        error: 'border-l-signal-danger bg-signal-danger50',
        warning: 'border-l-signal-warning bg-signal-warning50',
        info: 'border border-l-8 border-primary-purple bg-primary-white'
      },
      size: {
        default: '!px-4 !py-2',
        small: '!px-3 !py-1.5'
      }
    },
    defaultVariants: {
      variant: 'success',
      size: 'default'
    }
  }
);

export type NoddiFeedbackBoxProps = VariantProps<typeof toastVariants> & {
  heading?: string;
  description?: string;
  onClose?: () => void;
  className?: string;
};
export type NoddiFeedbackBoxSize = 'small' | 'default';

const NoddiFeedbackBox = ({ variant, heading, description, onClose, size, className }: NoddiFeedbackBoxProps) => {
  const isWithoutAnimation = onClose === undefined;
  const feedbackBoxSize = size === 'small' ? 'small' : 'medium';

  const hasDescription = !!description;
  return (
    <div className={cn(toastVariants({ variant, size }), isWithoutAnimation && '!animate-none', className)}>
      <div className='flex flex-col gap-1'>
        {heading && (
          <div className={cn('text-5.5', hasDescription && 'font-bold', feedbackBoxSize === 'small' && 'text-4')}>
            {heading}
          </div>
        )}
        {description && <div className={cn('text-4', feedbackBoxSize === 'small' && 'text-3.5')}>{description}</div>}
      </div>

      {onClose && (
        <NoddiIconButton
          onClick={() => {
            onClose();
          }}
          iconName='Cross'
          iconSize='large'
          variant='ghost'
        />
      )}
    </div>
  );
};

interface ToastsWrapperProps {
  children: ReactNode;
}
const NoddiFeedbackWrapper = ({ children }: ToastsWrapperProps) => {
  return <div className='fixed right-1 top-4 z-[1000] mx-2'>{children}</div>;
};
export { NoddiFeedbackBox, NoddiFeedbackWrapper };
