import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';

import { colors } from '../../tailwind-design-preset';
import { IconName, NoddiIcon } from '../atoms';
import { cn } from '../helpers/utilts';
import { NoddiIconButton } from '../molecules/NoddiIconButton';
import { ChipVariants } from './types/chip';

interface NoddiChipProps {
  label: ReactNode;
  onDelete?: () => void;
  variant?: ChipVariants;
  icon?: IconName;
}

const chipVariants = cva('flex h-10 w-fit items-center justify-center gap-2 whitespace-nowrap rounded-full px-4 py-1', {
  variants: {
    variant: {
      neutral: ' border border-primary-purple bg-primary-white text-primary-purple',
      success: ' bg-signal-success text-secondary-white',
      destructive: ' bg-signal-danger text-secondary-white',
      warning: ' border  border-signal-warning bg-signal-warning50 text-secondary-black'
    }
  },
  defaultVariants: {
    variant: 'neutral'
  }
});

const iconColorVariantsInNoddiButton = cva('', {
  variants: {
    variant: {
      neutral: colors.primary.purple,
      success: colors.secondary.white,
      destructive: colors.secondary.white,
      warning: colors.secondary.black
    }
  }
});

export const NoddiChip = ({ label, onDelete, variant, icon }: NoddiChipProps) => {
  const iconColor = iconColorVariantsInNoddiButton({ variant });

  return (
    <div className={cn(chipVariants({ variant }))}>
      {icon && <NoddiIcon name={icon} size='medium' color={iconColor} />}
      <span>{label}</span>
      {onDelete && (
        <NoddiIconButton
          variant='secondary'
          onClick={onDelete}
          className='border-0'
          iconName='Cross'
          iconSize='small'
        />
      )}
    </div>
  );
};
