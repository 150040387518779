import { TextField } from '@mui/material';
import { URLKeys, noddiAsync } from 'noddi-async';
import { OptionsTypeInteger } from 'noddi-async/src/types';
import { ApiErrorMessage, LoadingScreen, NoddiAutocomplete, NoddiContainer, NoddiDatePicker } from 'noddi-ui';
import { useState } from 'react';

import { PrintLabelsList } from './PrintLabelsList';

export const PrintLabelWrapper = () => {
  const [date, setDate] = useState(new Date());
  const [serviceWorker, setServiceWorker] = useState<OptionsTypeInteger>();

  const {
    data: serviceWorkerData,
    isPending: isServiceWorkerPending,
    error: IsServiceWorkerError
  } = noddiAsync.useGet({
    type: URLKeys.getServiceWorkers,
    input: {}
  });

  if (isServiceWorkerPending) {
    return <LoadingScreen />;
  }
  if (IsServiceWorkerError) {
    return <ApiErrorMessage error={IsServiceWorkerError} />;
  }

  const serviceWorkerOptions = serviceWorkerData.map((item) => ({
    value: item.id,
    label: item.user.name
  }));

  return (
    <NoddiContainer
      header='Print labels - tire hotel route'
      description='Print labels before starting your tire hotel route. These labels are used to mark the wheels we pick up from customers, before storing them in warehouse'
    >
      <div className='flex flex-col gap-8'>
        <div className='flex max-w-lg items-end gap-6'>
          <NoddiAutocomplete
            options={serviceWorkerOptions}
            fullWidth
            onChange={(_, newvalue) => setServiceWorker(newvalue as OptionsTypeInteger)}
            renderInput={(params) => <TextField {...params} label='Search for your name' />}
          />
          <NoddiDatePicker label='Choose date' value={date} onChange={(date) => setDate(date!)} />
        </div>
        <PrintLabelsList date={date} serviceWorker={serviceWorker} />
      </div>
    </NoddiContainer>
  );
};
