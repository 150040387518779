import { CircularProgress } from '@mui/material';
import { cva, type VariantProps } from 'class-variance-authority';
import { ButtonHTMLAttributes } from 'react';

import { colors } from '../../tailwind-design-preset';
import { IconName, NoddiIcon, sizeMap } from '../atoms';
import { cn } from '../helpers/utilts';

// Don't add hover as effect persists on mobile after pressed, which makes the button look disabled.
const buttonVariants = cva(
  'inline-flex h-fit min-w-fit items-center justify-center gap-2 whitespace-nowrap rounded-lg font-medium ring-offset-systemColors-rapsberryBg transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-systemColors-grey focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        primary: 'border border-primary-darkPurple bg-primary-darkPurple text-text-secondary active:bg-primary-purple',
        secondary:
          'border border-primary-darkPurple bg-primary-white text-primary-darkPurple active:bg-systemColors-snow  active:text-text-primaryInverted',
        ghost: 'text-text-primary active:bg-primary-white active:text-text-primaryInverted',
        success: 'border border-signal-success bg-signal-success text-text-secondary active:bg-signal-success/80',
        destructive: 'border border-signal-danger bg-signal-danger text-text-secondary active:bg-signal-danger/80',
        link: 'w-fit gap-1 whitespace-normal text-nowrap text-primary-purple underline-offset-4 hover:underline active:text-primary-purple/60',
        lightLink:
          'w-fit gap-1 whitespace-normal text-nowrap text-text-secondary underline-offset-4 hover:underline active:text-text-secondary/60'
      },
      size: {
        default: 'px-4 py-2',
        small: 'px-3 py-1'
      },
      fullWidth: {
        true: 'w-full',
        false: 'w-fit'
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
      fullWidth: false
    }
  }
);

const iconColorVariantsInNoddiButton = cva('', {
  variants: {
    variant: {
      primary: colors.text.secondary,
      secondary: colors.primary.darkPurple,
      ghost: colors.primary.black,
      success: colors.text.secondary,
      destructive: colors.text.secondary,
      link: colors.primary.purple,
      lightLink: colors.text.secondary
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    loading?: boolean;
    fullWidth?: boolean;
  } & ({ startIcon?: IconName; endIcon?: never } | { endIcon?: IconName; startIcon?: never });

export const NoddiButton = ({
  className,
  variant,
  size,
  endIcon,
  startIcon,
  loading,
  children,
  fullWidth,
  ...props
}: ButtonProps) => {
  const iconSize = size === 'small' ? 'small' : 'medium';
  const buttonClassName = cn(buttonVariants({ variant, size, fullWidth, className }));
  const iconColor = iconColorVariantsInNoddiButton({ variant });

  // We need type button, as type submit is default as causes bugs.
  return (
    <button className={buttonClassName} type='button' {...props}>
      {loading ? (
        <CircularProgress color='inherit' size={sizeMap[iconSize]} />
      ) : (
        <>
          {startIcon && (
            <div>
              <NoddiIcon color={iconColor} name={startIcon} size={iconSize} />
            </div>
          )}
          <span className='text-left'>{children}</span>
          {endIcon && (
            <div>
              <NoddiIcon color={iconColor} name={endIcon} size={iconSize} />
            </div>
          )}
        </>
      )}
    </button>
  );
};
