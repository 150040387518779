const appRoutesKeys = {
  home: '/',
  storageUnits: '/storage-units',
  globalPickingList: '/global-picking-list',
  login: '/login',
  profile: '/profile',
  printLabelsLicencePlateNumber: '/print-label-license-plate-number'
} as const;

type BaseRouteKeys = keyof typeof appRoutesKeys;

// Define the parameters for the routes
interface PathConfig {}

type InputData<Type extends BaseRouteKeys> = Type extends keyof PathConfig ? PathConfig[Type] : void;

type InputDataOrVoid<Type extends BaseRouteKeys> = InputData<Type> extends void ? void : InputData<Type>;

type RouteDefinition<key extends BaseRouteKeys> = {
  getPath(data: InputDataOrVoid<key>): string;
  getBasePath(): string;
};
const routes: {
  [key in BaseRouteKeys]: RouteDefinition<key>;
} = {
  login: {
    getPath: () => '/login',
    getBasePath: () => '/login'
  },
  profile: {
    getPath: () => '/profile',
    getBasePath: () => '/profile'
  },
  home: {
    getPath: () => '/',
    getBasePath: () => '/'
  },
  storageUnits: {
    getPath: () => '/storage-units',
    getBasePath: () => '/storage-units'
  },
  globalPickingList: {
    getPath: () => '/global-picking-list',
    getBasePath: () => '/global-picking-list'
  },
  printLabelsLicencePlateNumber: {
    getPath: () => '/print-label-license-plate-number',
    getBasePath: () => '/print-label-license-plate-number'
  }
};

export default routes;
